<template>
  <H5 v-if="isMobile" />
  <PC v-else />
</template>

<script lang="ts" setup>
import { ENUM_PageName } from '~/enums'
import PC from '~/pages/desktop/coins/index.vue'
import H5 from '~/pages/mobile/coins/index.vue'

definePageMeta({
  pageCategory: ENUM_PageName.Home,
})

const isMobile = useMobileByScreen()
</script>
